import { VueSlideToggle } from 'vue-slide-toggle';
import { BLOG_CONTENT_ASIDE_GROUP_TYPE } from '../../../../helpers';

export default {
  name: 'ContentBlockAsideGroup',
  components: { VueSlideToggle },
  props: {
    data: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      isOpen: true
    };
  },
  created() {},
  mounted() {},
  watch: {},
  computed: {
    BLOG_CONTENT_ASIDE_GROUP_TYPE() {
      return BLOG_CONTENT_ASIDE_GROUP_TYPE
    }
  },
  methods: {
    onClickSlide() {
      this.isOpen = !this.isOpen;
    }
  }
};
