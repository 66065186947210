import { mapMutations } from 'vuex';
import IconPlay from '@/components/icons/IconPlay.vue';
import { BLOG_VIDEOS_TYPE, getImage } from '@/helpers';

export default {
  name: 'VideoCard',
  props: {
    data: {
      type: Object,
      default: () => {}
    },
    type: {
      type: String,
      default: BLOG_VIDEOS_TYPE.VIDEOS
    }
  },
  data() {
    return {};
  },
  components: {
    IconPlay
  },
  computed: {
    image() {
      return getImage(this.data.image);
    }
  },
  mounted() {},
  methods: {
    ...mapMutations({
      showYoutubeVideoModal: 'popups/SHOW_YOUTUBE_VIDEO_MODAL'
    }),
    onHandleVideoPlay() {
      this.showYoutubeVideoModal({ id: this.data.video, status: true });
    }
  }
};
