import ContentBlockDescription from '@/components/custom/ContentBlock/ContentBlockDescription/index.vue';
import IconPlay from '@/components/icons/IconPlay.vue';
import { mapMutations } from 'vuex';

export default {
  name: 'ContentBlockModalVideo',
  components: { IconPlay, ContentBlockDescription },
  props: {
    data: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      isPlaying: false,
      isYouTubeLink: false,
      videoUrl: null
    };
  },
  created() {},
  mounted() {},
  computed: {},
  methods: {
    ...mapMutations({
      showYoutubeVideoModal: 'popups/SHOW_YOUTUBE_VIDEO_MODAL'
    }),
    onHandleVideoPlay() {
      this.showYoutubeVideoModal({ id: this.data.video, status: true });
    }
  }
};
