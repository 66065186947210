import IconClock from '@/components/icons/IconClock.vue';
import { pluralizate } from '@/helpers/variables';

export default {
  name: 'ContentBlockInfo',
  components: { IconClock },
  props: {
    tag: {
      type: Object,
      default: () => {}
    },
    date: {
      type: String,
      default: ''
    },
    commentsCount: {
      type: [Number, null],
      default: null
    }
  },
  data() {
    return {};
  },
  created() {},
  mounted() {},
  computed: {
    commentsPluralizate() {
      return pluralizate(
        this.commentsCount ? this.commentsCount: 0,
        this.$t('label.comments.countOne'),
        this.$t('label.comments.countSimple'),
        this.$t('label.comments.countMany')
      );
    },
  },
  methods: {}
};
