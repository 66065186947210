import Pagination from '@/components/@general/Pagination/index.vue';
import VideoCard from '@/components/cards/VideosCard/index.vue';

export default {
  name: 'ContentBlockGrid',
  components: { VideoCard, Pagination },
  props: {
    data: {
      type: Array,
      default: () => []
    },
    meta: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {};
  },
  created() {},
  mounted() {},
  computed: {},
  methods: {}
};
