import BreadcrumbsSimple from '@/components/@general/BreadcrumbsSimple/index.vue';
import { mapActions } from 'vuex';
import {
  BLOG_BLOCK_TYPE,
  BLOG_CONTENT_ASIDE_GROUP_TYPE,
  BLOG_CONTENT_TYPE,
  COMMENT_TYPE,
  countComments
} from '@/helpers';

import ContentBlockAsideGroup from '@/components/custom/ContentBlock/ContentBlockAsideGroup/index.vue';
import ContentBlockGrid from '@/components/custom/ContentBlock/ContentBlockGrid/index.vue';
import ContentBlockInfo from '@/components/custom/ContentBlock/ContentBlockInfo/index.vue';
import ContentBlockTitleDescription from '@/components/custom/ContentBlock/ContentBlockTitleDescription/index.vue';
import ContentBlockImage from '@/components/custom/ContentBlock/ContentBlockImage/index.vue';
import ContentBlockVideo from '@/components/custom/ContentBlock/ContentBlockVideo/index.vue';
import ContentBlockPhrase from '@/components/custom/ContentBlock/ContentBlockPhrase/index.vue';
import CommentsBlock from '@/components/custom/CommentsBlock/index.vue';
import ContentBlockModalVideo from '@/components/custom/ContentBlock/ContentBlockModalVideo/index.vue';

export default {
  name: 'ContentBlock',
  components: {
    ContentBlockModalVideo,
    CommentsBlock,
    ContentBlockPhrase,
    ContentBlockVideo,
    ContentBlockImage,
    ContentBlockTitleDescription,
    ContentBlockInfo,
    ContentBlockGrid,
    ContentBlockAsideGroup,
    BreadcrumbsSimple
  },
  props: {
    breadcrumbsTitle: {
      type: String,
      default: ''
    },
    breadcrumbsList: {
      type: Array,
      default: () => []
    },
    title: {
      type: String,
      default: ''
    },
    data: {
      type: [Object, Array, null],
      default: () => null
    },
    meta: {
      type: [Object, null],
      default: () => null
    },
    type: {
      type: String,
      default: BLOG_CONTENT_TYPE.CONTENTS
    },
    pageType: {
      type: [String, null],
      default: null
    },
    isHasAside: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      COMMENT_TYPE,
      BLOG_CONTENT_TYPE,
      BLOG_BLOCK_TYPE,
      asideData: [],
      commentsCount: null
    };
  },
  watch: {
    data: {
      immediate: false,
      handler(newVal) {
        if (newVal) {
          if (!this.isArchive && this.data.comments && this.data.comments.data.length) {
            this.commentsCount = countComments(this.data.comments.data, COMMENT_TYPE.COMMENTS);
          }
        }
      }
    }
  },
  async created() {
    await this._loadContent();
  },
  mounted() {},
  computed: {
    isArchive() {
      return this.type === BLOG_CONTENT_TYPE.ARCHIVE;
    },
    contents() {
      let contents = [];
      if (this.data.contents && this.data.contents.data.length) {
        contents = this.data.contents.data;
      } else if (this.data.page_contents && this.data.page_contents.data.length) {
        contents = this.data.page_contents.data;
      }
      return contents;
    }
  },
  methods: {
    ...mapActions({
      getBlogFresh: 'blog/GET_BLOG_FRESH',
      getBlogCategories: 'blog/GET_BLOG_CATEGORIES',
      getBlogArchives: 'blog/GET_BLOG_ARCHIVES'
    }),
    async _loadContent() {
      let promiseCollection = [];
      let data = {};
      promiseCollection.push(this.getBlogFresh().then((result) => (data['fresh'] = result)));
      promiseCollection.push(this.getBlogCategories().then((result) => (data['categories'] = result)));
      promiseCollection.push(this.getBlogArchives().then((result) => (data['archives'] = result)));
      await Promise.all(promiseCollection);
      this._prepareAside(data);
    },
    _prepareAside({ fresh, categories, archives }) {
      if (fresh?.length) {
        this.asideData.push({
          title: this.$t('block.contentAside.fresh'),
          list: fresh.map(({ title, slug }) => ({ title, route: { name: 'news-single', params: { slug } } })),
          type: BLOG_CONTENT_ASIDE_GROUP_TYPE.FRESH
        });
      }

      if (categories?.length) {
        this.asideData.push({
          title: this.$t('block.contentAside.categories'),
          list: categories.map(({ title, slug, newsCount }) => ({
            title: title ? title : slug,
            route: { path: `/${slug}` },
            count: newsCount
          })),
          type: BLOG_CONTENT_ASIDE_GROUP_TYPE.CATEGORIES
        });
      }

      if (archives?.length) {
        this.asideData.push({
          title: this.$t('block.contentAside.archivies'),
          list: archives.map(({ title, month, year }) => ({
            title: `${title} ${year}`,
            route: { name: 'archive', params: { year, month: month < 10 ? `0${month}` : month } }
          })),
          type: BLOG_CONTENT_ASIDE_GROUP_TYPE.ARCHIVES
        });
      }
    },
    onUpdateCounter() {
      this.commentsCount++;
    }
  }
};
