var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"content-block"},[(_vm.breadcrumbsTitle)?_c('BreadcrumbsSimple',{attrs:{"title":_vm.breadcrumbsTitle,"list":_vm.breadcrumbsList}}):_vm._e(),_c('div',{staticClass:"app-container"},[_c('div',{staticClass:"content-block__grid"},[(_vm.data)?_c('div',{staticClass:"content-block__main"},[(_vm.title || _vm.data.title)?_c('h1',{staticClass:"content-block__title",domProps:{"innerHTML":_vm._s(_vm.title ? _vm.title : _vm.data.title)}}):_vm._e(),(_vm.isArchive)?_c('ContentBlockGrid',{attrs:{"data":_vm.data,"meta":_vm.meta,"type":_vm.type}}):_vm._e(),(!_vm.isArchive)?[_c('ContentBlockInfo',{attrs:{"comments-count":_vm.commentsCount,"date":_vm.data.publishedAt,"tag":_vm.breadcrumbsList.length ? _vm.breadcrumbsList[0] : null}}),(_vm.type === _vm.BLOG_CONTENT_TYPE.CONTENTS)?[(_vm.data.description || _vm.data.image)?_c('ContentBlockImage',{attrs:{"data":{
                description: _vm.data.description,
                image: _vm.data.image
              }}}):_vm._e(),(_vm.data.video)?_c('ContentBlockVideo',{attrs:{"data":{
                video: _vm.data.video
              }}}):_vm._e(),(_vm.contents.length)?_c('div',{staticClass:"content-block__contents"},[_vm._l((_vm.contents),function(block){return [(block.type === _vm.BLOG_BLOCK_TYPE.BLOCK_WITH_VIDEO)?_c('ContentBlockVideo',{key:((block.type) + "_" + (block.id)),attrs:{"data":block}}):_vm._e(),(
                    block.type === _vm.BLOG_BLOCK_TYPE.TITLE_WITH_TITLE_DESCRIPTION ||
                    block.type === _vm.BLOG_BLOCK_TYPE.TITLE_WITH_DESCRIPTION ||
                    block.type === _vm.BLOG_BLOCK_TYPE.DESCRIPTION ||
                    block.type === _vm.BLOG_BLOCK_TYPE.CONTENT_TITLE
                  )?_c('ContentBlockTitleDescription',{key:((block.type) + "_" + (block.id)),attrs:{"position-center":block.type === _vm.BLOG_BLOCK_TYPE.TITLE_WITH_TITLE_DESCRIPTION,"data":block}}):_vm._e(),(block.type === _vm.BLOG_BLOCK_TYPE.PHRASE)?_c('ContentBlockPhrase',{key:((block.type) + "_" + (block.id)),attrs:{"data":block}}):_vm._e(),(block.type === _vm.BLOG_BLOCK_TYPE.BLOCK_WITH_IMAGE)?_c('ContentBlockImage',{key:((block.type) + "_" + (block.id)),attrs:{"data":block}}):_vm._e()]})],2):_vm._e()]:[_c('ContentBlockModalVideo',{attrs:{"data":{
                description: _vm.data.description,
                image: _vm.data.image,
                video: _vm.data.video
              }}})],(_vm.data.comments)?_c('div',{staticClass:"content-block__comments"},[_c('CommentsBlock',{attrs:{"id":_vm.data.id,"title":_vm.data.title,"data":_vm.data.comments.data,"type":_vm.COMMENT_TYPE.COMMENTS},on:{"comment-counter":_vm.onUpdateCounter}})],1):_vm._e()]:_vm._e()],2):_vm._e(),(_vm.asideData.length && _vm.isHasAside)?_c('div',{staticClass:"content-block__aside"},_vm._l((_vm.asideData),function(item){return _c('ContentBlockAsideGroup',{key:item.type,attrs:{"data":item}})}),1):_vm._e()])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }